<template>
  <div>
    <b-card
      :class="$store.state.appConfig.layout.skin === 'dark' ? 'dark-card rounded-lg' : 'rounded-lg'"
    >
      <b-row>
        <b-col md="2"></b-col>
        <b-col md="8">
          <b-toast
            primary
            no-auto-hide
            noCloseButton
            v-model="showToast"
            append-toast
            b-toaster-bottom-center
            id="example-toast"
          >
            <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-center mr-1 pt-2">
                <strong class="mr-auto">Uploading ...</strong>
              </div>
            </template>
            <div v-if="uploadProgress" class="progress-wrapper">
              <b-card-text class="mb-0">{{ uploadProgress ? uploadProgress + "%" : "" || "" }}</b-card-text>
              <b-progress v-model="uploadProgress" max="100" />
            </div>
          </b-toast>
          <!-- {{ loadItem }} -->
          <center v-if="loadItem">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </center>
          <b-form v-else class="chat-app-form" @submit.prevent="submit">
            <validation-observer class="w-100" ref="moduleForm" #default="{invalid}">
              <b-row class="pt-3">
                <b-col md="6" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-group label="Module Name *" label-for="name">
                      <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input
                          autocomplete="off"
                          v-model="form.name"
                          placeholder="Enter Module Name"
                          id="name"
                          class="custom-field"
                        />
                      </b-input-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="mb-2" v-if="courses">
                  <validation-provider
                    #default="{ errors }"
                    name="course_id"
                    vid="course_id"
                    rules="required"
                  >
                  <!-- {{ form.course_id.id }} -->
                    <b-form-group label="Module Course *" label-for="course_id">
                      <v-select
                        v-model="form.course_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="courses"
                        id="course_id"
                        class="w-100 mb-0 custom-field"
                        :reduce="(val) => val.id"
                        placeholder="Select Course"
                        :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                      >
                        <template
                          v-slot:option="option"
                        >{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                        <template #selected-option="{ name, name_local }">
                          <div style="display: flex; align-items: baseline">
                            <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                            <strong v-else>{{ name }}</strong>
                          </div>
                        </template>
                        <template #no-options>{{ $t("noMatching") }}</template>
                      </v-select>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    vid="description"
                    rules="required"
                  >
                    <b-form-group label="Module Description *" label-for="description">
                      <!-- <b-input-group
                          class="input-group-merge form-send-message mr-1"
                        >
                          <b-form-textarea
                            id="textarea-default"
                            v-model="form.description"
                            placeholder="Enter Module Description"
                            class="custom-field"
                          ></b-form-textarea>
                      </b-input-group> -->
                      <quill-editor v-model="form.description" :options="snowOption">
                        <div id="toolbar" slot="toolbar">
                          <select class="ql-font">
                            <option selected="selected" />
                            <option value="serif" />
                            <option value="monospace" />
                          </select>
                          <button class="ql-bold">Bold</button>
                          <button class="ql-underline" value="super" />
                          <button class="ql-italic">Italic</button>
                          <button class="ql-list" value="ordered" />
                          <button class="ql-list" value="bullet" />
                          <select class="ql-size">
                            <option value="small" />
                            <option selected />
                            <option value="large" />
                            <option value="huge" />
                          </select>
                          <select class="ql-header"></select>
                          <button class="ql-list" />
                          <button class="ql-script" value="sub" />
                          <button class="ql-script" value="super" />
                          <button class="ql-link" value="super" />
                          <select class="ql-align"></select>
                          <select class="ql-color"></select>
                          <select class="ql-background"></select>
                        </div>
                      </quill-editor>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                
                <b-col md="12" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Module Content"
                    vid="content"
                    rules="required"
                  >
                    <b-form-group label="Module Content *" label-for="content">
                      <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-textarea
                          id="textarea-default"
                          v-model="form.content"
                          placeholder="Enter Module Content"
                          class="custom-field"
                        ></b-form-textarea>
                      </b-input-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="What You Will Learn"
                    vid="learn"
                    rules="required"
                  >
                    <b-form-group label="What You Will Learn *" label-for="learn">
                      <b-form-tags
                        id="learn"
                        input-id="learn"
                        v-model="form.learn"
                        class="custom-field"
                      ></b-form-tags>
                      <small class="text-danger float-left" v-if="errors">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="12" class="mb-2">
                  <b-row>
                    <b-col md="6" class="mb-2">
                      <b-row>
                        <b-col md="12" class="mb-2">
                          <validation-provider
                            #default="{ errors }"
                            name="author_name"
                            vid="author_name"
                            rules="required"
                          >
                            <b-form-group label="Author Name*" label-for="author_name">
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                <b-form-input
                                  autocomplete="off"
                                  v-model="form.author_name"
                                  placeholder="Enter Author Name"
                                  id="name"
                                  class="custom-field"
                                />
                              </b-input-group>
                              <small class="text-danger float-left">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="12" class="mb-2">
                          <validation-provider
                            #default="{ errors }"
                            name="author_title"
                            vid="author_title"
                            rules="required"
                          >
                            <b-form-group label="Author title*" label-for="author_title">
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                <b-form-input
                                  autocomplete="off"
                                  v-model="form.author_title"
                                  placeholder="Enter Author title"
                                  id="name"
                                  class="custom-field"
                                />
                              </b-input-group>
                              <small class="text-danger float-left">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      md="6"
                      class="mb-2"
                      v-if="EditCertificateLogo || id == null || item.certificate_logo == null"
                    >
                      <b-form-group label="Certificate Logo" label-for="logo">
                        <b-form-file
                          id="log"
                          v-model="form.certificate_logo"
                          :state="Boolean(form.certificate_logo)"
                          accept="image/*"
                          class="custom-field"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" class="mb-2" v-if="!EditCertificateLogo && id != null && item.cover_image">
                      <div
                        class="d-flex align-items-center mb-1 cursor-pointer"
                        @click="EditCertificateLogo = true"
                      >
                        <feather-icon
                          :tooltip="'none'"
                          icon="EditIcon"
                          size="20"
                          class="cursor-pointer text-link mr-1"
                          v-b-tooltip.hover.top="'Edit Image'"
                        />
                        <small>Edit Certificate Logo</small>
                      </div>
                      <img :src="item.certificate_logo" alt width="100%" />
                    </b-col>
                  </b-row>
                </b-col>
                
                <b-col
                  md="6"
                  class="mb-2"
                  v-if="editCoverPhoto || id == null || item.cover_image == null"
                >
                  <b-form-group label="Module Cover Photo" label-for="cover">
                    <b-form-file
                      id="cover"
                      v-model="form.cover_image"
                      :state="Boolean(form.cover_image)"
                      accept="image/*"
                      class="custom-field"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  class="mb-2"
                  v-if="editCoverVideo || id == null || item.overview_video == null"
                >
                  <!-- <validation-provider
                      #default="{ errors }"
                      name="video"
                      vid="video"
                      rules="required"
                  >-->
                  <b-form-group label="Module Overview Video" label-for="video">
                    <b-form-file
                      id="video"
                      v-model="form.overview_video"
                      :state="Boolean(form.overview_video)"
                      accept="video/*"
                      class="custom-field"
                    />
                  </b-form-group>
                  <!-- <small class="text-danger float-left">{{ errors[0] }}</small>
                  </validation-provider>-->
                </b-col>
                <b-col md="6" class="mb-2" v-if="!editCoverPhoto && id != null && item.cover_image">
                  <div
                    class="d-flex align-items-center mb-1 cursor-pointer"
                    @click="editCoverPhoto = true"
                  >
                    <feather-icon
                      :tooltip="'none'"
                      icon="EditIcon"
                      size="20"
                      class="cursor-pointer text-link mr-1"
                      v-b-tooltip.hover.top="'Edit Image'"
                    />
                    <small>Edit Cover Image</small>
                  </div>
                  <img :src="item.cover_image" alt width="100%" />
                </b-col>
                <b-col
                  md="6"
                  class="mb-2"
                  v-if="!editCoverVideo && id != null && item.overview_video"
                >
                  <div
                    class="d-flex align-items-center mb-1 cursor-pointer"
                    @click="editCoverVideo = true"
                  >
                    <feather-icon
                      :tooltip="'none'"
                      icon="EditIcon"
                      size="20"
                      class="cursor-pointer text-link mr-1"
                      v-b-tooltip.hover.top="'Edit Video'"
                    />
                    <small>Edit Overview Video</small>
                  </div>
                  <video width="100%" height="auto" controls>
                    <source :src="item.overview_video" type="video/mp4" />
                    <source :src="item.overview_video" type="video/ogg" />Your browser does not support the video tag.
                  </video>
                </b-col>
              </b-row>
            </validation-observer>
            <div class="w-100 d-flex justify-content-center">
              <b-button
                variant="outline-secondary"
                class="rounded-pill mt-2 mr-1"
                @click="$router.go(-1)"
              >Cancel</b-button>
              <b-button
                variant="primary"
                :class="uploadProgress && uploadProgress != 100 ? 'rounded-pill mt-2 cursor-disabled' : 'rounded-pill mt-2'"
                type="submit"
                :disabled="uploadProgress && uploadProgress != 100 ? true : false"
              >Submit</b-button>
            </div>
          </b-form>
        </b-col>
        <b-col md="2"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  VBTooltip,
  BForm,
  BFormTags,
  BToast,
  BProgress,
  BFormFile,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "quill/dist/quill.core.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormFile,
    BToast,
    BProgress,
    BFormTags,
    vSelect,
    flatPickr,
    required,
    quillEditor,
    BSpinner
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapGetters({
      item: "modules/item",
      loadItem: "modules/loadItem",
      courses: "courses/lookups",
      uploadProgress: "app/uploadProgress"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    course_id() {
      return this.$route.query.course_id ? this.$route.query.course_id : null;
    }
  },
  data() {
    return {
      editCoverPhoto: false,
      editCoverVideo: false,
      EditCertificateLogo: false,
      showToast: false,
      snowOption: {
        modules: {
          toolbar: "#toolbar"
        },
        theme: "snow"
      },
      form: {
        name: null,
        course_id: null,
        description: null,
        cover_image: null,
        certificate_logo: null,
        content: null,
        learn: null,
        overview_video: null,
        author_name:null,
        author_title:null,
      }
    };
  },
  methods: {
    init() {
      this.$store.dispatch("courses/lookups").then(_ => {
        this.form.course_id = this.course_id ? this.course_id : null;
        
        if (this.id) {
          this.$store.dispatch("modules/get", this.id).then(_ => {
            this.form.name = this.item.name;
            this.form.course_id = this.item.course_id;
            this.form.description = this.item.description;
            this.form.author_name = this.item.author_name;
            this.form.author_title = this.item.author_title;
            this.form.learn = this.item.learn;
            this.form.content = this.item.content;
            // this.form.cover_image = this.item.cover_image
            // this.form.overview_video = this.item.overview_video
          });
        }
      });
    },
    submit() {
      this.$refs.moduleForm.validate().then(success => {
        if (success) {
          this.showToast = true;
          if (this.id) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              formData.append(key, value);
            }
            formData.learn = this.form.learn;
            const payload = { id: this.id, query: formData };
            this.$store
              .dispatch("modules/put", payload)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                // if (error.response) {
                this.$swal({
                  icon: "error",
                  title: `<h4>${error.response.data.message}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: "#E84185",
                  allowOutsideClick: true
                });
                // }
              });
          } else {
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              formData.append(key, value);
            }
            const payload = { course_id: this.course_id, query: formData };
            this.$store
              .dispatch("modules/put", payload)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                if (error.response) {
                  this.$swal({
                    icon: "error",
                    title: `<h4>${error.response.data.message}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: "#E84185",
                    allowOutsideClick: true
                  });
                }
              });
          }
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

[dir] .custom-file-label {
  border: unset !important;
  background: transparent !important;
  border-bottom: 1px solid #d8d6de !important;
  cursor: pointer !important;
}
input.is-invalid:focus ~ .custom-file-label {
  box-shadow: unset !important;
}

[dir] .custom-file-label::after {
  padding: 0.6rem 1rem !important;
  background-color: transparent !important;
  color: white !important;
}
.custom-file-label::after {
  border-radius: 0.357rem !important;
}

.custom-file-label::after {
  content: "" !important; /* Optional: Customize the text displayed on hover */
  cursor: pointer !important; /* Indicate clickable area */
  /* Add padding or other styles for a custom look */
}
</style>
